/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useMemo, useState } from 'react';
import { Space, Button, Select, Row, Col } from 'antd';
import Icon, { RightOutlined, LoadingOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import { HomeLined } from 'components/atoms/SVG';
import { useQuery } from '@tanstack/react-query';

import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
  BarElement,
} from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { Doughnut, Pie, Bar } from 'react-chartjs-2';

import {
  getTotalPenghimpunanDana,
  getPenghimpunanDanaByProposal,
  getPenghimpunanDanaByFinancialInstrument,
  getDonorCount,
  getDonorList,
  getNilaiKomitmenByDonor,
  getProposalCountByType,
  getSubmissionProposalCountByStatus,
  getTopDonors,
} from 'services/dashboard';
import { proposalStatus, proposalStatusBgColor } from 'constant/common';

ChartJS.register(
  ArcElement,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
  BarElement,
  ChartDataLabels
);

export const data = {
  labels: [
    'Thematic 1',
    'Thematic 2',
    'Thematic 3',
    'Thematic 4',
    'Thematic 5',
  ],
  datasets: [
    {
      label: 'Dana Program',
      data: [100000000, 100000000, 110000000, 60000000, 130000000],
      // datalabels: {
      // 	anchor: 'center',
      // 	backgroundColor: null,
      // 	borderWidth: 0
      // },
      backgroundColor: [
        'rgba(255, 99, 132, 1)',
        'rgba(54, 162, 235, 1)',
        'rgba(255, 206, 86, 1)',
        'rgba(75, 192, 192, 1)',
        'rgba(255, 159, 64, 1)',
      ],
      borderColor: ['#FFF', '#FFF', '#FFF', '#FFF', '#FFF'],
      borderWidth: 3,
    },
  ],
};

export const data2 = {
  labels: ['FPCF', 'TA', 'Proyek C', 'Proyek D', 'Proyek F'],
  datasets: [
    {
      label: 'Dana Project',
      data: [1000000000, 300000000, 100000000, 250000000, 350000000],
      backgroundColor: [
        'rgba(255, 99, 132, 1)',
        'rgba(54, 162, 235, 1)',
        'rgba(255, 206, 86, 1)',
        'rgba(75, 192, 192, 1)',
        'rgba(255, 159, 64, 1)',
      ],
      borderColor: ['#FFF', '#FFF', '#FFF', '#FFF', '#FFF'],
      borderWidth: 3,
    },
  ],
};

// const labels2 = ['Jumlah Proposal'];
// export const dataBar2 = {
//   labels2,
//   datasets: [
//     {
//       label: 'Baru',
//       data: [7],
//       backgroundColor: '#01A24A',
//       stack: 'Stack 2',
//       borderRadius: 30,
//     },
//     {
//       label: 'Sedang Direview',
//       data: [20],
//       backgroundColor: 'rgba(54, 162, 235, 1)',
//       stack: 'Stack 3',
//       borderRadius: 30,
//     },
//     {
//       label: 'Disetujui',
//       data: [11],
//       backgroundColor: 'rgba(255, 206, 86, 1)',
//       stack: 'Stack 4',
//       borderRadius: 30,
//     },
//     {
//       label: 'Ditolak',
//       data: [5],
//       backgroundColor: 'rgba(255, 99, 132, 1)',
//       stack: 'Stack 5',
//       borderRadius: 30,
//     },
//   ],
// };

const Dashboard = () => {
  let navigate = useNavigate();
  const spinIcon = <LoadingOutlined style={{ fontSize: 60 }} spin />;

  const options = {
    responsive: true,
    maintainAspectRatio: true,
    plugins: {
      legend: {
        display: true,
        position: 'right',
        labels: {
          usePointStyle: true,
          pointStyle: 'circle',
        },
      },
      datalabels: {
        formatter: (value, ctx) => {
          let sum = 0;
          let dataArr = ctx.chart.data.datasets[0].data;
          dataArr.map((data) => {
            sum += data;
          });
          let percentage = (value * 100) / sum;
          if (percentage > 2.5) {
            return percentage.toFixed(2) + '%';
          } else {
            return '';
          }
        },
        color: '#fff',
      },
      aspectRatio: 4 / 3,
      cutoutPercentage: 32,
      layout: {
        padding: 32,
      },
      elements: {
        line: {
          fill: false,
        },
        point: {
          hoverRadius: 7,
          radius: 5,
        },
      },
    },
  };

  const optionsBar = {
    plugins: {
      //   title: {
      // 	display: true,
      // 	text: 'Chart.js Bar Chart - Stacked',
      //   },
      datalabels: {
        formatter: (value, ctx) => {
          return '';
        },
        color: '#fff',
      },
      legend: {
        display: true,
        position: 'bottom',
        labels: {
          usePointStyle: true,
          pointStyle: 'circle',
          // generateLabels: (chart) => {
          //   console.log(chart.legend);
          //   return [1, 2];
          // },
        },
      },
    },
    responsive: true,
    interaction: {
      mode: 'index',
      intersect: false,
    },
    scales: {
      x: {
        stacked: true,
      },
      y: {
        stacked: true,
      },
    },
  };

  // data
  const [donorList, setDonorList] = useState();
  const [topDonors, setTopDonors] = useState();
  const [selectedDonor, setSelectedDonor] = useState(
    '666129bcfd5f196e3a55b86f'
  );

  // requests
  useQuery({
    queryKey: ['donor-list'],
    queryFn: async () => {
      const res = await getDonorList();

      if (res.data.status === 200) {
        const list = res.data.result.map((data) => ({
          value: data.id,
          label: data.name,
        }));

        setDonorList(list);
      }

      return res;
    },
  });

  const { data: dataPenghimpunanDana } = useQuery({
    queryKey: ['total-penghimpunan-dana'],
    queryFn: async () => {
      const res = await getTotalPenghimpunanDana();

      return res?.data?.result[0];
    },
  });

  const { data: dataPenghimpunanDanaByProposal } = useQuery({
    queryKey: ['penghimpunan-dana-by-proposal'],
    queryFn: async () => {
      const res = await getPenghimpunanDanaByProposal();

      return res?.data?.result;
    },
  });

  const { data: dataPenghimpunanDanaByFinancialInstrument } = useQuery({
    queryKey: ['penghimpunan-dana-by-financial-instrument'],
    queryFn: async () => {
      const res = await getPenghimpunanDanaByFinancialInstrument();

      return res?.data?.result[0];
    },
  });

  const { data: dataDonor } = useQuery({
    queryKey: ['donor-count'],
    queryFn: async () => {
      const res = await getDonorCount();

      return res.data.result;
    },
  });

  // TODO: Make sure list donor dan idnya darimana
  // const { data: dataKomitmenByDonor } = useQuery({
  //   enabled: !!selectedDonor,
  //   queryKey: ['komitmen-by-donor', selectedDonor],
  //   queryFn: async () => {
  //     const res = await getNilaiKomitmenByDonor(selectedDonor);

  //     console.log(res.data);

  //     return res.data.result;
  //   },
  // });

  const { data: dataProposalByType } = useQuery({
    queryKey: ['proposal-by-type'],
    queryFn: async () => {
      const res = await getProposalCountByType();

      return res.data.result;
    },
  });

  const { data: dataProposalByStatus } = useQuery({
    queryKey: ['proposal-by-status'],
    queryFn: async () => {
      const res = await getSubmissionProposalCountByStatus();

      return res.data.result;
    },
  });

  const { data: dataTopDonors } = useQuery({
    queryKey: ['top-donors'],
    queryFn: async () => {
      const res = await getTopDonors();

      return res.data.result;
    },
  });

  // memo
  const totalPenghimpunanDana = useMemo(() => {
    const dataset = {
      labels: ['Dana Masuk', 'Total Penghimpunan Dana'],
      datasets: [
        {
          data: [
            dataPenghimpunanDana?.totalDanaMasuk,
            dataPenghimpunanDana?.totalNilaiKomitmen -
              dataPenghimpunanDana?.totalDanaMasuk,
          ],
          backgroundColor: ['rgba(54, 162, 235, 1)', '#e3e3e3'],
          borderColor: ['#FFF', '#FFF'],
          borderWidth: 3,
        },
      ],
    };

    return dataset;
  }, [dataPenghimpunanDana]);

  const dataBar = useMemo(() => {
    const dataset = {
      labels: dataPenghimpunanDanaByProposal?.map(
        (data) => data.nama.slice(0, 20) + (data.nama.length > 20 ? '...' : '')
      ),
      datasets: [
        {
          label: 'Nilai Komitmen',
          data: dataPenghimpunanDanaByProposal?.map(
            (data) => data.nilai_komitmen
          ),
          backgroundColor: '#01A24A',
          stack: 'Stack 0',
          borderRadius: 30,
        },
        {
          label: 'Dana Masuk',
          data: dataPenghimpunanDanaByProposal?.map(
            (data) => data.dana_komitmen
          ),
          backgroundColor: 'rgb(53, 162, 235)',
          stack: 'Stack 1',
          borderRadius: 30,
        },
      ],
    };

    return dataset;
  }, [dataPenghimpunanDanaByProposal]);

  const data3 = useMemo(() => {
    const dataset = {
      labels: ['Grant', 'Revolving Fund', 'Endowment Fund'],
      datasets: [
        {
          label: 'Dana Program',
          data: [
            dataPenghimpunanDanaByFinancialInstrument?.totalGrant,
            dataPenghimpunanDanaByFinancialInstrument?.totalRevolvingFund,
            dataPenghimpunanDanaByFinancialInstrument?.totalEndowmentFund,
          ],
          backgroundColor: [
            'rgba(255, 99, 132, 1)',
            'rgba(54, 162, 235, 1)',
            'rgba(255, 206, 86, 1)',
          ],
          borderColor: ['#FFF', '#FFF', '#FFF'],
          borderWidth: 3,
        },
      ],
    };

    return dataset;
  }, [dataPenghimpunanDanaByFinancialInstrument]);

  const donorCount = useMemo(() => {
    return dataDonor?.count?.count;
  }, [dataDonor]);

  const countProposalByType = useMemo(() => {
    return {
      submission: dataProposalByType?.submission_count,
      nonSubmission: dataProposalByType?.non_submission_count,
    };
  }, [dataProposalByType]);

  const dataBar2 = useMemo(() => {
    if (dataProposalByStatus) {
      const approved = dataProposalByStatus?.filter(
        (data) => data.status === 'approved'
      )[0];

      const rejected = dataProposalByStatus?.filter(
        (data) => data.status === 'rejected'
      )[0];

      const review = dataProposalByStatus?.filter(
        (data) => data.status === 'review'
      )[0];

      const draft = dataProposalByStatus?.filter(
        (data) => data.status === 'draft'
      )[0];

      const dataset = {
        labels: ['Jumlah Proposal Submission'],
        datasets: [
          {
            label: proposalStatus['approved'],
            data: [approved.count],
            backgroundColor: proposalStatusBgColor['approved'],
            stack: `Stack 1`,
            borderRadius: 30,
          },
          {
            label: proposalStatus['rejected'],
            data: [rejected.count],
            backgroundColor: proposalStatusBgColor['rejected'],
            stack: `Stack 2`,
            borderRadius: 30,
          },
          {
            label: proposalStatus['review'],
            data: [review.count],
            backgroundColor: proposalStatusBgColor['review'],
            stack: `Stack 3`,
            borderRadius: 30,
          },
          {
            label: proposalStatus['draft'],
            data: [draft.count],
            backgroundColor: proposalStatusBgColor['draft'],
            stack: `Stack 4`,
            borderRadius: 30,
          },
        ],
      };

      return dataset;
    }
  }, [dataProposalByStatus]);

  // cycle
  useEffect(() => {
    if (dataTopDonors) {
      // labels: ['Dana Masuk', 'Nilai Komitmen']
      const dataset = dataTopDonors?.map((donor) => ({
        detail: donor._id,
        danaMasuk: donor.totalDanaMasuk,
        nilaiKomitmen: donor.totalNilaiKomitmen,
        datasets: [
          {
            data: [donor.totalDanaMasuk, donor.totalNilaiKomitmen],
            backgroundColor: ['rgba(54, 162, 235, 1)', '#e3e3e3'],
            borderColor: ['#FFF', '#FFF'],
            borderWidth: 3,
          },
        ],
      }));

      setTopDonors(dataset);
    }
  }, [dataTopDonors]);

  return (
    <>
      <div className="flex items-center py-4 flex-wrap">
        <Space>
          <Icon component={HomeLined} className="mr-2" />
          <RightOutlined className="mr-3 text-xs right-cursor" />
        </Space>
        <div>
          <p className="font-medium" style={{ color: '#01A24A' }}>
            Dashboard PPD
          </p>
        </div>
      </div>
      <div className="flex items-center justify-between mb-4">
        <h1 className="text-2xl font-medium">
          Dashboard Penghimpunan & Pengembangan Dana
        </h1>
        <div className="flex justify-between" style={{ width: '40%' }}>
          <Select
            size="small"
            placeholder="Bulan"
            allowClear
            type="text"
            className="mt-2"
            style={{ height: '48px', width: '48%' }}
            options={[
              { value: 'Januari', label: 'Januari' },
              { value: 'Februari', label: 'Februari' },
              { value: 'Maret', label: 'Maret' },
            ]}
          />
          <Select
            size="small"
            placeholder="Tahun"
            allowClear
            type="text"
            className="mt-2"
            style={{ height: '48px', width: '48%' }}
            options={[
              { value: '2023', label: '2023' },
              { value: '2024', label: '2024' },
            ]}
          />
        </div>
      </div>

      {/* TOTAL PENGHIMPUNAN DANA */}
      <div
        className="table-list"
        style={{
          background: '#01A24A',
          border: '1px solid #EAECF0',
          borderRadius: '12px',
        }}
      >
        <div className="flex justify-between p-8 gap-5">
          <div className="flex-1 bg-white rounded-lg">
            <div className="flex justify-between p-3">
              <div className="text-2xl font-medium">
                Total Penghimpunan Dana
              </div>
            </div>
            <hr className="mb-6" />

            <div
              style={{
                background: '#01A24A',
                borderRadius: '8px',
                padding: 20,
                margin: 20,
                height: 86,
              }}
            >
              <div
                style={{
                  background: '#FAFAFA',
                  border: '1px solid #EAECF0',
                  borderRadius: '8px',
                  padding: 10,
                }}
              >
                {`Rp ${dataPenghimpunanDana?.totalNilaiKomitmen.toLocaleString()}`}
              </div>
            </div>

            <div className="flex justify-center items-center h-[500px]">
              {totalPenghimpunanDana && (
                <Doughnut
                  data={totalPenghimpunanDana}
                  options={options}
                  width={10}
                />
              )}
            </div>
            <hr />
          </div>

          {/* TODO: Hide temporarily */}
          {/* <div
            className="block"
            style={{
              background: '#FFF',
              borderRadius: '8px',
              width: '49%',
              minHeight: 250,
            }}
          >
            <div className="flex justify-between p-3">
              <div className="text-2xl font-medium">
                Nilai Penyaluran: Rp.500.000.000,-
              </div>
              <div className="cursor-pointer">...</div>
            </div>
            <hr className="mb-6" />

            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                width: '100%',
                height: 350,
                marginTop: 63,
                marginBottom: 63,
              }}
            >
              <div style={{ width: '60%' }}>
                <Pie data={data} options={options} />
              </div>
            </div>
            <hr />

            <div
              style={{
                display: 'flex',
                justifyContent: 'end',
                width: '100%',
                height: 82,
              }}
            >
              <Button
                className="btn-back"
                style={{ textAlign: 'right', margin: 15 }}
              >
                <span className="text-center">Lihat Detail</span>
              </Button>
            </div>
          </div> */}
        </div>
      </div>

      {/* PENGHIMPUNAN DANA */}
      <div
        className="block mt-8"
        style={{
          background: '#FFF',
          borderRadius: '8px',
          width: '100%',
          minHeight: 250,
        }}
      >
        <div className="flex justify-between p-3">
          <div className="text-2xl font-medium">Penghimpunan Dana</div>
        </div>
        <hr className="mb-6" />

        <div
          style={{ display: 'flex', justifyContent: 'center', width: '100%' }}
        >
          <div style={{ width: '100%', margin: 20 }}>
            {dataBar && (
              <Bar data={dataBar} options={optionsBar} height={100} />
            )}
          </div>
        </div>

        {/* <div
          style={{
            display: 'flex',
            justifyContent: 'end',
            width: '100%',
            height: 82,
          }}
        >
          <Button
            className="btn-back"
            style={{ textAlign: 'right', margin: 15 }}
          >
            <span className="text-center">Lihat Detail</span>
          </Button>
        </div> */}
      </div>

      {/* Penghimpunan Dana - Berdasarkan Finansial Instrumen */}
      <div className="flex justify-between mt-4 bg-transparent rounded-lg">
        {/* TODO: Hide temporarily */}
        {/* <div className='flex justify-between'> */}
        {/* <div
          className="block mt-4"
          style={{
            background: '#FFF',
            borderRadius: '8px',
            width: '49%',
            minHeight: 250,
          }}
        >
          <div className="flex justify-between p-3">
            <div className="text-2xl font-medium">
              Penghimpunan Dana - Berdasarkan Program Tematik
            </div>
            <div className="cursor-pointer">...</div>
          </div>
          <hr className="mb-6" />

          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              width: '100%',
              height: 350,
              marginTop: 63,
              marginBottom: 63,
            }}
          >
            <div style={{ width: '60%' }}>
              <Doughnut data={data} options={options} />
            </div>
          </div>
          <hr />

          <div
            style={{
              display: 'flex',
              justifyContent: 'end',
              width: '100%',
              height: 82,
            }}
          >
            <Button
              className="btn-back"
              style={{ textAlign: 'right', margin: 15 }}
            >
              <span className="text-center">Lihat Detail</span>
            </Button>
          </div>
        </div> */}

        <div
          className="flex-1 mt-4"
          style={{
            background: '#FFF',
            borderRadius: '8px',
            width: 'w-full',
          }}
        >
          <div className="flex justify-between p-3">
            <div className="text-2xl font-medium">
              Penghimpunan Dana - Berdasarkan Finansial Instrumen
            </div>
          </div>

          <hr className="mb-6" />

          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              width: '100%',
              height: 350,
            }}
          >
            <div>{data3 && <Doughnut data={data3} options={options} />}</div>
          </div>
        </div>
      </div>

      <div
        className="flex justify-between mt-4"
        style={{
          background: 'transparent',
          borderRadius: '8px',
          width: '100%',
          minHeight: 250,
        }}
      >
        <div
          className="block mt-4"
          style={{
            background: '#FFF',
            borderRadius: '8px',
            width: '49%',
            minHeight: 250,
            borderTop: 'solid 8px #01A24A',
          }}
        >
          <div className="flex justify-between p-3">
            <div className="text-2xl font-medium"> Donor</div>
          </div>
          <hr className="mb-6" />

          <div
            style={{
              background: '#01A24A',
              borderRadius: '8px',
              padding: 20,
              margin: 20,
            }}
          >
            <div
              style={{
                background: '#FAFAFA',
                border: '1px solid #EAECF0',
                borderRadius: '8px',
                padding: 10,
              }}
            >
              <b>Total Donor</b>
              <p>{donorCount}</p>
            </div>
          </div>

          <div
            className="block mt-4"
            style={{
              background: '#FFF',
              borderRadius: '8px',
              margin: 20,
              minHeight: 250,
              border: 'solid 1px #e5e7eb',
            }}
          >
            <div className="flex justify-between p-3">
              <div className="text-2xl font-medium">
                World Bank <br />
                <span style={{ color: '#01A24A', fontSize: 20 }}>
                  Total: Rp. 2000.000.000
                </span>
              </div>

              <Select
                size="small"
                placeholder="Donor"
                allowClear
                type="text"
                className="mt-2"
                style={{ height: '48px', width: '48%' }}
                onChange={(value) => setSelectedDonor(value)}
                options={donorList}
              />
            </div>

            <hr className="mb-6" />

            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                width: '100%',
                height: 350,
                marginTop: 63,
                marginBottom: 63,
              }}
            >
              <div style={{ width: '60%' }}>
                {data2 && <Doughnut data={data2} options={options} />}
              </div>
            </div>
          </div>
        </div>

        {/* Total Proposal Submission */}
        <div
          className="block mt-4"
          style={{
            background: '#01A24A',
            borderRadius: '8px',
            width: '49%',
            minHeight: 250,
          }}
        >
          <div
            style={{
              background: '#01A24A',
              borderRadius: '8px',
              padding: 20,
              margin: 20,
              height: 86,
            }}
          >
            <div
              style={{
                background: '#FAFAFA',
                border: '1px solid #EAECF0',
                borderRadius: '8px',
                padding: 10,
              }}
            >
              <b>Total Proposal Submission</b>
              <p>{countProposalByType.submission}</p>
            </div>
          </div>
          <div
            style={{
              background: '#01A24A',
              borderRadius: '8px',
              padding: 20,
              margin: 20,
              marginTop: 0,
              height: 86,
            }}
          >
            <div
              style={{
                background: '#FAFAFA',
                border: '1px solid #EAECF0',
                borderRadius: '8px',
                padding: 10,
              }}
            >
              <b>Total Proposal Non Submission</b>
              <p>{countProposalByType.nonSubmission}</p>
            </div>
          </div>
          <div
            className="block mt-4"
            style={{
              background: '#FFF',
              borderRadius: '8px',
              margin: 40,
              minHeight: 250,
            }}
          >
            <div className="flex justify-between p-3">
              <div className="text-2xl font-medium">
                Progres Status Proposal Submission
              </div>
              <div className="cursor-pointer">...</div>
            </div>
            <hr className="mb-6" />

            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                width: '100%',
              }}
            >
              <div style={{ width: '100%', margin: 20 }}>
                {dataBar2 && <Bar data={dataBar2} options={optionsBar} />}
              </div>
            </div>
          </div>
        </div>
        {/* </div> */}
      </div>

      <div
        className="block mt-4"
        style={{
          background: '#FFF',
          borderRadius: '8px',
          margin: 20,
          minHeight: 250,
          borderTop: 'solid 8px #01A24A',
        }}
      >
        <div className="flex justify-between p-3">
          <div className="text-2xl font-medium">
            Top 4 Donor Berdasarkan Dana Masuk
          </div>
        </div>
        <hr className="mb-6" />

        <Row>
          {topDonors &&
            topDonors.map((donor) => (
              <Col span={12}>
                <div className="block mt-4 bg-white rounded-lg m-5 border border-[#e5e7eb]">
                  <div className="flex justify-between p-3">
                    <div className="text-2xl font-medium">
                      {donor?.detail?.donor}
                    </div>
                  </div>
                  <hr className="mb-6" />

                  <div className="bg-[#01A24A] rounded-lg p-2 m-5">
                    <div className="bg-[#FAFAFA] border border-[#EAECF0] rounded-lg p-2 text-[#01A24A]">
                      <p>Total Komitmen</p>
                      <b>Rp {donor?.nilaiKomitmen?.toLocaleString()}</b>
                    </div>
                  </div>

                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      width: '100%',
                      height: 350,
                      marginTop: 63,
                      marginBottom: 63,
                    }}
                  >
                    <div style={{ width: '60%' }}>
                      <Doughnut
                        data={{
                          labels: ['Dana Masuk', 'Nilai Komitmen'],
                          datasets: donor?.datasets,
                        }}
                        options={options}
                      />
                    </div>
                  </div>
                </div>
              </Col>
            ))}
        </Row>
      </div>
    </>
  );
};

export default Dashboard;
