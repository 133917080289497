import { IDENTITY_CONFIG, METADATA_OIDC } from '../app/authConst';
import { UserManager, Log, WebStorageStateStore } from 'oidc-client';
import { StorageAuthStateStore } from '../app/storageAuthStateStore';
import store from '../app/store';
import Cookies from 'js-cookies';

export default class AuthService {
  UserManager;

  constructor() {
    this.UserManager = new UserManager({
      ...IDENTITY_CONFIG,
      userStore: new StorageAuthStateStore(),
      stateStore: new WebStorageStateStore(),
      metadata: {
        ...METADATA_OIDC,
      },
    });

    // Logger
    Log.logger = console;
    Log.level = Log.DEBUG;
  }

  signinRedirectCallback = () => {
    this.UserManager.signinRedirectCallback().then(
      () => {
        window.history.replaceState(
          {},
          window.document.title,
          window.location.origin
        );
        //this.navigateToScreen();
        window.location = localStorage.getItem('redirectUri');
      },
      (err) => {
        console.log('Error caught in signinRedirectCallback().');
        console.error(err);
        alert('Error caught in signinRedirectCallback()');
      }
    );
  };

  signinRedirect = () => {
    localStorage.setItem('redirectUri', '/dashboard');
    this.UserManager.signinRedirect({});
  };

  navigateToScreen = () => {
    window.location.replace(localStorage.getItem('redirectUri'));
  };

  isAuthenticated = () => {
    const user = store.getState().oidc.oidc_user;

    return !!user;
  };

  signinSilent = () => {
    this.UserManager.signinSilent()
      .then((user) => {
        console.log('signed in', user);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  signinSilentCallback = () => {
    this.UserManager.signinSilentCallback();
  };

  logout = () => {
    this.UserManager.clearStaleState();
    this.UserManager.removeUser();
    this.UserManager.signoutRedirect();
  };

  signoutRedirectCallback = () => {
    this.UserManager.removeUser();
    this.UserManager.clearStaleState();
    this.UserManager.signoutRedirectCallback().then(() => {
      localStorage.clear();
      window.location.replace('/');
    });
  };
}
