import React, { useState } from 'react';
import _ from 'lodash';
import { useLocation, useNavigate } from 'react-router';
import image from '../../../assets/Logo.png';
import '../../styles/menu.css';
// import imageSVG from "../../../assets/Logo.svg";

const Sidebar = ({ children }) => {
  const location = useLocation();
  const navigate = useNavigate();
  <svg></svg>;

  const handleMenu = (key, showChildren) => {
    const updatedMenu = [...menu];

    updatedMenu[key] = {
      ...updatedMenu[key],
      showChildren: !showChildren,
    };

    setMenu(updatedMenu);
  };

  const [menu, setMenu] = useState([
    {
      icon: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
        >
          <path
            d="M3 21C2.73478 21 2.48043 20.8946 2.29289 20.7071C2.10536 20.5196 2 20.2652 2 20V4C2 3.73478 2.10536 3.48043 2.29289 3.29289C2.48043 3.10536 2.73478 3 3 3H10.414L12.414 5H20C20.2652 5 20.5196 5.10536 20.7071 5.29289C20.8946 5.48043 21 5.73478 21 6V9H19V7H11.586L9.586 5H4V16.998L5.5 11H22.5L20.19 20.243C20.1358 20.4592 20.011 20.6512 19.8352 20.7883C19.6595 20.9255 19.4429 21 19.22 21H3ZM19.938 13H7.062L5.562 19H18.438L19.938 13Z"
            fill="#F2F4F7"
          />
        </svg>
      ),
      label: 'Dashboard',
      path: '/dashboard',
    },
    {
      icon: (
        <svg
          width="24"
          height="24"
          fill="none"
          aria-hidden="true"
          class="w-6 h-6 text-gray-500 transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M17 2H20C20.2652 2 20.5196 2.10536 20.7071 2.29289C20.8946 2.48043 21 2.73478 21 3V21C21 21.2652 20.8946 21.5196 20.7071 21.7071C20.5196 21.8946 20.2652 22 20 22H4C3.73478 22 3.48043 21.8946 3.29289 21.7071C3.10536 21.5196 3 21.2652 3 21V3C3 2.73478 3.10536 2.48043 3.29289 2.29289C3.48043 2.10536 3.73478 2 4 2H7V0H9V2H15V0H17V2ZM17 4V6H15V4H9V6H7V4H5V20H19V4H17ZM7 8H17V10H7V8ZM7 12H17V14H7V12Z"
            fill="#F2F4F7"
          ></path>
        </svg>
      ),
      label: 'Daftar Donor',
      path: '/data-donor',
    },
    {
      icon: (
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          aria-hidden="true"
          class="flex-shrink-0 w-6 h-6 text-gray-500 transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill-rule="evenodd"
            d="M2 3.993C2.00183 3.73038 2.1069 3.47902 2.29251 3.29322C2.47813 3.10742 2.72938 3.00209 2.992 3H21.008C21.556 3 22 3.445 22 3.993V20.007C21.9982 20.2696 21.8931 20.521 21.7075 20.7068C21.5219 20.8926 21.2706 20.9979 21.008 21H2.992C2.72881 20.9997 2.4765 20.895 2.29049 20.7088C2.10448 20.5226 2 20.2702 2 20.007V3.993ZM11 5H4V19H11V5ZM13 5V19H20V5H13ZM14 7H19V9H14V7ZM14 10H19V12H14V10Z"
            fill="#F2F4F7"
            clip-rule="evenodd"
          ></path>
        </svg>
      ),
      label: 'Daftar Proposal',
      parentPath: '/proposal',
      showChildren: false,
      children: [
        {
          child: (
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              aria-hidden="true"
              class="flex-shrink-0 w-6 h-6 text-gray-500 transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                d="M2 3.993C2.00183 3.73038 2.1069 3.47902 2.29251 3.29322C2.47813 3.10742 2.72938 3.00209 2.992 3H21.008C21.556 3 22 3.445 22 3.993V20.007C21.9982 20.2696 21.8931 20.521 21.7075 20.7068C21.5219 20.8926 21.2706 20.9979 21.008 21H2.992C2.72881 20.9997 2.4765 20.895 2.29049 20.7088C2.10448 20.5226 2 20.2702 2 20.007V3.993ZM11 5H4V19H11V5ZM13 5V19H20V5H13ZM14 7H19V9H14V7ZM14 10H19V12H14V10Z"
                fill="#F2F4F7"
                clip-rule="evenodd"
              ></path>
            </svg>
          ),
          label: 'Proposal Submission',
          path: '/proposal-submission',
        },
        {
          child: (
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              aria-hidden="true"
              class="flex-shrink-0 w-6 h-6 text-gray-500 transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                d="M2 3.993C2.00183 3.73038 2.1069 3.47902 2.29251 3.29322C2.47813 3.10742 2.72938 3.00209 2.992 3H21.008C21.556 3 22 3.445 22 3.993V20.007C21.9982 20.2696 21.8931 20.521 21.7075 20.7068C21.5219 20.8926 21.2706 20.9979 21.008 21H2.992C2.72881 20.9997 2.4765 20.895 2.29049 20.7088C2.10448 20.5226 2 20.2702 2 20.007V3.993ZM11 5H4V19H11V5ZM13 5V19H20V5H13ZM14 7H19V9H14V7ZM14 10H19V12H14V10Z"
                fill="#F2F4F7"
                clip-rule="evenodd"
              ></path>
            </svg>
          ),
          label: 'Proposal Non-Submission',
          path: '/proposal-non-submission',
        },
      ],
    },
    {
      icon: (
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clip-path="url(#clip0_9644_84214)">
            <path
              d="M17 15.245V22.117C17 22.2054 16.9766 22.2923 16.932 22.3688C16.8875 22.4452 16.8235 22.5085 16.7465 22.5521C16.6696 22.5957 16.5824 22.6181 16.4939 22.617C16.4055 22.616 16.3189 22.5914 16.243 22.546L12 20L7.757 22.546C7.68104 22.5915 7.59436 22.616 7.50581 22.617C7.41727 22.6181 7.33004 22.5956 7.25304 22.5518C7.17605 22.5081 7.11205 22.4447 7.06759 22.3681C7.02313 22.2915 6.9998 22.2045 7 22.116V15.246C5.70615 14.2101 4.76599 12.7979 4.30946 11.2045C3.85293 9.61117 3.90256 7.91536 4.45149 6.35145C5.00043 4.78753 6.02156 3.43272 7.37378 2.47425C8.726 1.51578 10.3425 1.00098 12 1.00098C13.6575 1.00098 15.274 1.51578 16.6262 2.47425C17.9784 3.43272 18.9996 4.78753 19.5485 6.35145C20.0974 7.91536 20.1471 9.61117 19.6905 11.2045C19.234 12.7979 18.2939 14.2101 17 15.246V15.245ZM9 16.418V19.468L12 17.668L15 19.468V16.418C14.0468 16.8035 13.0282 17.0011 12 17C10.9718 17.0011 9.95316 16.8035 9 16.418ZM12 15C13.5913 15 15.1174 14.3678 16.2426 13.2426C17.3679 12.1174 18 10.5913 18 8.99997C18 7.40868 17.3679 5.88255 16.2426 4.75733C15.1174 3.63212 13.5913 2.99997 12 2.99997C10.4087 2.99997 8.88258 3.63212 7.75736 4.75733C6.63214 5.88255 6 7.40868 6 8.99997C6 10.5913 6.63214 12.1174 7.75736 13.2426C8.88258 14.3678 10.4087 15 12 15Z"
              fill="#F2F4F7"
            />
          </g>
          <defs>
            <clipPath id="clip0_9644_84214">
              <rect width="24" height="24" fill="white" />
            </clipPath>
          </defs>
        </svg>
      ),
      label: 'Perjanjian',
      path: '/perjanjian',
    },
    // {
    //   icon: (
    //     <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
    //       <path d="M5 8V20H19V8H5ZM5 6H19V4H5V6ZM20 22H4C3.73478 22 3.48043 21.8946 3.29289 21.7071C3.10536 21.5196 3 21.2652 3 21V3C3 2.73478 3.10536 2.48043 3.29289 2.29289C3.48043 2.10536 3.73478 2 4 2H20C20.2652 2 20.5196 2.10536 20.7071 2.29289C20.8946 2.48043 21 2.73478 21 3V21C21 21.2652 20.8946 21.5196 20.7071 21.7071C20.5196 21.8946 20.2652 22 20 22ZM7 10H11V14H7V10ZM7 16H17V18H7V16ZM13 11H17V13H13V11Z" fill="#F2F4F7" />
    //     </svg>
    //   ),
    //   label: "Daftar Dokumen Program",
    //   path: "/dokumen-program",
    // },
    {
      icon: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
        >
          <path
            d="M3 3H21C21.2652 3 21.5196 3.10536 21.7071 3.29289C21.8946 3.48043 22 3.73478 22 4V20C22 20.2652 21.8946 20.5196 21.7071 20.7071C21.5196 20.8946 21.2652 21 21 21H3C2.73478 21 2.48043 20.8946 2.29289 20.7071C2.10536 20.5196 2 20.2652 2 20V4C2 3.73478 2.10536 3.48043 2.29289 3.29289C2.48043 3.10536 2.73478 3 3 3ZM20 11H4V19H20V11ZM20 9V5H4V9H20ZM14 15H18V17H14V15Z"
            fill="#F2F4F7"
          />
        </svg>
      ),
      label: 'Daftar Rekening',
      path: '/rekening',
    },
    {
      icon: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
        >
          <path
            d="M3 21C2.73478 21 2.48043 20.8946 2.29289 20.7071C2.10536 20.5196 2 20.2652 2 20V4C2 3.73478 2.10536 3.48043 2.29289 3.29289C2.48043 3.10536 2.73478 3 3 3H10.414L12.414 5H20C20.2652 5 20.5196 5.10536 20.7071 5.29289C20.8946 5.48043 21 5.73478 21 6V9H19V7H11.586L9.586 5H4V16.998L5.5 11H22.5L20.19 20.243C20.1358 20.4592 20.011 20.6512 19.8352 20.7883C19.6595 20.9255 19.4429 21 19.22 21H3ZM19.938 13H7.062L5.562 19H18.438L19.938 13Z"
            fill="#F2F4F7"
          />
        </svg>
      ),
      label: 'Dokumen Penghimpunan',
      path: '/rekap',
    },
    // {
    //   icon: (
    //     <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
    //       <path d="M3 21C2.73478 21 2.48043 20.8946 2.29289 20.7071C2.10536 20.5196 2 20.2652 2 20V4C2 3.73478 2.10536 3.48043 2.29289 3.29289C2.48043 3.10536 2.73478 3 3 3H10.414L12.414 5H20C20.2652 5 20.5196 5.10536 20.7071 5.29289C20.8946 5.48043 21 5.73478 21 6V9H19V7H11.586L9.586 5H4V16.998L5.5 11H22.5L20.19 20.243C20.1358 20.4592 20.011 20.6512 19.8352 20.7883C19.6595 20.9255 19.4429 21 19.22 21H3ZM19.938 13H7.062L5.562 19H18.438L19.938 13Z" fill="#F2F4F7" />
    //     </svg>
    //   ),
    //   label: "Tes Identity Server",
    //   path: "/identity",
    // },
  ]);

  return (
    <aside
      className="hidden sm:block w-80 overflow-y-auto border-gray-500"
      style={{
        boxShadow: '1px 1px 5px 1px #0000001F',
        backgroundColor: '#101828',
      }}
    >
      <div className="relative">
        <img src={image} className="w-[183px] h-[83px] my-8 ml-4" alt="" />
      </div>
      <div className="w-full font-sans text-sm text-center p-4 my-6 text-[#D0D5DD] bg-[#344054]">
        Penghimpunan & Pengembangan Dana
      </div>
      <ul className="space-y-2 m-4">
        {_.map(
          menu,
          ({ label, icon, path, parentPath, children, showChildren }, key) => (
            <li key={key} className="cursor-pointer">
              <div
                onClick={() => {
                  if (path) {
                    navigate(path);
                  } else {
                    handleMenu(key, showChildren);
                  }
                }}
                className={`flex items-center p-2 text-base font-semibold text-sm text-[#ffffff] rounded-lg hover:bg-[#01A24A] 
                ${
                  (location?.pathname?.includes(path) ||
                    location?.pathname?.includes(parentPath)) &&
                  'bg-[#01A24A]'
                }`}
              >
                {icon}
                <span className="ml-3">{label}</span>
              </div>

              {children && showChildren && (
                <div className={showChildren ? 'show-submenu' : 'hide-submenu'}>
                  <ul className="space-y-2 m-4">
                    {children.map(({ label, child, path }, key) => (
                      <li key={key} className="cursor-pointer">
                        <div
                          onClick={() => {
                            navigate(path);
                          }}
                          className={`flex items-center p-2 text-base font-semibold text-sm text-[#ffffff] rounded-lg hover:bg-[#01A24A] ${
                            location?.pathname?.includes(path) && 'bg-[#01A24A]'
                          }`}
                        >
                          {child}
                          <span className="ml-3">{label}</span>
                        </div>
                      </li>
                    ))}
                  </ul>
                </div>
              )}
            </li>
          )
        )}
      </ul>
      <div className="text-white ml-4 mt-12 font-normal text-sm absolute bottom-4">
        © BPDLH 2023, Version 1.0
      </div>
    </aside>
  );
};

export default Sidebar;
