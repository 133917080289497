import React, { useState, useEffect } from "react";
import { Button, Col, Modal, Row, Select } from "antd";
import {
   PlusOutlined,
   DeleteOutlined
} from '@ant-design/icons';
import { OutputForm } from "./OutputForm";
import axios from "axios";
import { MASTER_DATA_URL } from "services/config";
import { ReactComponent as Confirmation } from "../../../assets/logo/Confirmation.svg"

export const OutcomeForm = ({ Form, form, disable, name, priorityProgramIndex }) => {
   const [listOutcome, setListOutcome] = useState([]);
   const [listIndicatorOutcome, setListIndicatorOutcome] = useState([]);

   const loadListOutcome = () => {
      axios.get(`${MASTER_DATA_URL}/Outcome/list`)
         .then(res => {
            let arr = [];

            res.data.data?.forEach(item => {
               arr.push({
                  ...item,
                  key: item.id,
                  value: item.numberingFormat + " " + item.nama,
                  label: item.numberingFormat + " " + item.nama
               })
            })

            setListOutcome(arr);
         })
         .catch(err => {
            console.log(err)
         })
   }

   const checkOutcomeValue = (key, outcomeIndex) => {
      let obj = form.getFieldsValue([name]);
      let priorityPrograms = obj[name];

      let outcome;
      if (name === "priorityPrograms") {
         outcome = priorityPrograms[priorityProgramIndex].impact.outcomes[outcomeIndex];
      } else {
         outcome = priorityPrograms[priorityProgramIndex].outcomes[outcomeIndex];
      }

      if (outcome) {
         if (key === "all") {
            return outcome;
         } else {
            return outcome[key];
         }
      } else {
         return undefined;
      }
   }

   const loadListIndicatorOutcome = () => {
      axios.get(`${MASTER_DATA_URL}/Indicator/list`)
         .then(res => {
            let arr = [];

            res.data.data?.forEach(item => {
               arr.push({
                  ...item,
                  key: item.id,
                  value: item.numberingFormat + " " + item.nama,
                  label: item.numberingFormat + " " + item.nama
               })
            })
            setListIndicatorOutcome(arr);
         })
         .catch(err => {
            console.log(err)
         })
   }

   useEffect(() => {
      loadListIndicatorOutcome();
      loadListOutcome();
   }, [])

   const handleIndicatorList = (outcomeIndex) => {
      let arr = []

      listIndicatorOutcome?.forEach(item => {
         let parentSelected = (item.id === checkOutcomeValue("originalId", outcomeIndex) && item.type === "Outcome")

         if (parentSelected) {
            item.indicator.forEach(indicator => {
               arr.push({
                  ...item,
                  key: indicator.id,
                  value: indicator.numberingFormat + " " + indicator.nama,
                  label: indicator.numberingFormat + " " + indicator.nama
               })
            })
         }
      })

      return arr;
   }


   const handleDeleteOutcome = (remove, idx) => {
      Modal.confirm({
         centered: true,
         title: '',
         icon: <Confirmation />,
         okText: "Confirm",
         bodyStyle: { justifyContent: "center" },
         content: (
            <>
               <h1 className="mb-4" style={{ fontSize: "17px", fontWeight: "600", lineHeight: "24px", justifyContent: "center" }}>
                  Anda yakin menghapus Outcome ini?
               </h1>
            </>
         ),
         onOk() {
            remove(idx)
         }
      })
   }

   return (
      <Form.List name={(name === "priorityPrograms") ? [priorityProgramIndex, "impact", "outcomes"] : [priorityProgramIndex, "outcomes"]}>
         {(outcomes, { add, remove }) => (
            <>
               {outcomes.map((outcome, outcomeIndex) => {
                  return (
                     <div key={outcome.key}>
                        <div className="px-4 py-4 mt-4" style={{ border: "1px solid #EAECF0", background: "#FFFF" }}>
                           <Row gutter={12}>
                              <Col span={disable ? 24 : 21}
                                 xs={disable ? 24 : 12}
                                 sm={disable ? 24 : 12}
                                 md={disable ? 24 : 16}
                                 lg={disable ? 24 : 18}
                                 xl={disable ? 24 : 20}
                              >
                                 <div className="">
                                    <label className="font-medium">Outcome</label>&nbsp;
                                    {(outcomeIndex > 0) && !disable &&
                                       <span><DeleteOutlined onClick={() => { handleDeleteOutcome(remove, outcomeIndex) }} /></span>
                                    }
                                    <Form.Item
                                       name={[outcomeIndex, 'outcomeName']}
                                    >
                                       <Select
                                          placeholder="Outcome"
                                          type="text"
                                          className="mt-2"
                                          disabled={disable}
                                          options={listOutcome}
                                          onChange={(value, option) => {
                                             let obj = form.getFieldsValue([name]);
                                             let priorityPrograms = obj[name];

                                             let outcome;
                                             if (name === "priorityPrograms") {
                                                outcome = priorityPrograms[priorityProgramIndex].impact.outcomes[outcomeIndex];
                                             } else {
                                                outcome = priorityPrograms[priorityProgramIndex].outcomes[outcomeIndex];
                                             }

                                             outcome = {
                                                ...outcome,
                                                originalId: option.id,
                                                outcomeName: value
                                             }

                                             if (name === "priorityPrograms") {
                                                form.setFieldValue([name, priorityProgramIndex, "impact", "outcomes", outcomeIndex], outcome)
                                             } else {
                                                form.setFieldValue([name, priorityProgramIndex, "outcomes", outcomeIndex], outcome)
                                             }
                                          }}
                                       />
                                    </Form.Item>
                                 </div>
                              </Col>

                              {!disable &&
                                 <Col span={3} xs={12} sm={12} md={8} lg={6} xl={4}>
                                    <Button
                                       icon={<PlusOutlined />}
                                       className="btn-primary mt-8 w-full"
                                       disabled={disable}
                                       onClick={() => {
                                          let obj = form.getFieldsValue([name]);
                                          let priorityPrograms = obj[name];

                                          let outcomes;
                                          if (name === "priorityPrograms") {
                                             outcomes = priorityPrograms[priorityProgramIndex].impact.outcomes;
                                          } else {
                                             outcomes = priorityPrograms[priorityProgramIndex].outcomes;
                                          }

                                          if (outcomes[outcomeIndex]) {
                                             if (outcomes[outcomeIndex].outputs) {
                                                outcomes[outcomeIndex].outputs.push({
                                                   outputName: null,
                                                })
                                             } else {
                                                outcomes[outcomeIndex] = {
                                                   ...outcomes[outcomeIndex],
                                                   outputs: [{
                                                      outputName: null
                                                   }]
                                                }
                                             }
                                          } else {
                                             outcomes[outcomeIndex] = { outcomeName: null, outputs: [] }
                                          }

                                          if (name === "priorityPrograms") {
                                             form.setFieldValue([name, priorityProgramIndex, "impact", "outcomes"], outcomes)
                                          } else {
                                             form.setFieldValue([name, priorityProgramIndex, "outcomes"], outcomes)
                                          }
                                       }}
                                    >
                                       Output
                                    </Button>
                                 </Col>
                              }
                           </Row>

                           <Row gutter={12}>
                              <Col span={24}>
                                 <label className="font-medium">Indicator</label>
                                 <Form.Item style={{ margin: 0 }}
                                    shouldUpdate
                                 >
                                    <Form.List name={[outcomeIndex, "indicators"]}>
                                       {(indicators, { add, remove }) => (
                                          <>
                                             {indicators.map((indicator, index) => {
                                                return (
                                                   <Row gutter={12}>
                                                      <Col span={disable ? 24 : 22}>
                                                         <Form.Item
                                                            style={{ margin: 10 }}
                                                            name={[index, "indicatorName"]}
                                                         >
                                                            <Select
                                                               placeholder="Indicator"
                                                               type="text"
                                                               // className="mt-2"
                                                               disabled={disable}
                                                               options={handleIndicatorList(outcomeIndex)}
                                                               onChange={(value, option) => {
                                                                  let indicator = {
                                                                     originalId: option.id,
                                                                     indicatorName: value
                                                                  }
                                                                  form.setFieldValue([name, priorityProgramIndex, "impact", "outcomes", outcomeIndex, "indicators", index], indicator)
                                                               }}
                                                            />
                                                         </Form.Item>
                                                      </Col>
                                                      {(index > 0) && !disable &&
                                                         <Col span={1}>
                                                            <Button
                                                               style={{ width: "100%", height: "48" }}
                                                               className="btn-default mt-2"
                                                               onClick={() => remove(index)}
                                                            >
                                                               <DeleteOutlined />
                                                            </Button>
                                                         </Col>
                                                      }
                                                      {(index > (indicators.length - 2)) && !disable &&
                                                         <Col span={1}>
                                                            <Button
                                                               style={{ width: "100%", height: "48px" }}
                                                               className="btn-default mt-2"
                                                               onClick={() => add()}
                                                            >
                                                               <PlusOutlined />
                                                            </Button>
                                                         </Col>
                                                      }
                                                   </Row>
                                                )
                                             })}
                                          </>
                                       )}
                                    </Form.List>
                                 </Form.Item>
                              </Col>
                           </Row>

                           <hr style={{ width: "100%" }} className="break-line" />
                           <div>
                              <OutputForm
                                 Form={Form}
                                 form={form}
                                 disable={disable}
                                 name={name}
                                 priorityProgramIndex={priorityProgramIndex}
                                 outcomeIndex={outcomeIndex}
                              />
                           </div>
                        </div>
                     </div>
                  )
               })}

               {!disable &&
                  <div className="flex items-center justify-center">
                     <Button
                        style={{ width: "100%", height: "52px" }}
                        className="btn-default mt-4"
                        disabled={disable}
                        icon={<PlusOutlined />}
                        onClick={(e) => {
                           add({ outcomeName: null, outputs: [], indicators: [{}] });
                        }}
                     >
                        Tambah Outcome
                     </Button>
                  </div>
               }
            </>
         )
         }
      </Form.List>
   )
}