import React, { useState, useEffect } from 'react';
import { Space, Table, Button, Spin } from 'antd';
import Icon, { RightOutlined, LoadingOutlined } from '@ant-design/icons';
import axios from 'axios';
import { useParams } from 'react-router-dom';

import { BASE_URL, OIDC } from 'services/config';

const HomeLined = () => (
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5.66667 13.1667H12.3333M8.18141 1.30333L2.52949 5.69927C2.15168 5.99312 1.96278 6.14005 1.82669 6.32405C1.70614 6.48704 1.61633 6.67065 1.56169 6.86588C1.5 7.08627 1.5 7.32558 1.5 7.80421V13.8333C1.5 14.7667 1.5 15.2335 1.68166 15.59C1.84144 15.9036 2.09641 16.1585 2.41002 16.3183C2.76654 16.5 3.23325 16.5 4.16667 16.5H13.8333C14.7668 16.5 15.2335 16.5 15.59 16.3183C15.9036 16.1585 16.1586 15.9036 16.3183 15.59C16.5 15.2335 16.5 14.7667 16.5 13.8333V7.80421C16.5 7.32558 16.5 7.08627 16.4383 6.86588C16.3837 6.67065 16.2939 6.48704 16.1733 6.32405C16.0372 6.14005 15.8483 5.99312 15.4705 5.69927L9.81859 1.30333C9.52582 1.07562 9.37943 0.961766 9.21779 0.918001C9.07516 0.879384 8.92484 0.879384 8.78221 0.918001C8.62057 0.961766 8.47418 1.07562 8.18141 1.30333Z"
      stroke="#667085"
      stroke-width="1.66667"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);

const DetailDataDonor = () => {
  const params = useParams();
  const spinIcon = <LoadingOutlined style={{ fontSize: 60 }} spin />;

  const [dataDonor, setDataDonor] = useState();
  const [acreditedEntities, setAcreditedEntities] = useState();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    loadDetailDataDonor();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loadDetailDataDonor = () => {
    axios.get(`${BASE_URL}/donor/${params.id}`).then((res) => {
      setLoading(false);
      setDataDonor(res.data.result);
      setAcreditedEntities(res.data.result.pic);
    });
  };

  const columns = [
    {
      title: 'PIC',
      dataIndex: 'pic',
      key: 'pic',
      width: '33%',
      sortDirections: ['descend', 'ascend'],
      sorter: (a, b) => a.pic?.localeCompare(b.pic),
    },
    {
      title: 'Alamat',
      dataIndex: 'address',
      key: 'address',
      width: '33%',
      sortDirections: ['descend', 'ascend'],
      sorter: (a, b) => a.address?.localeCompare(b.address),
    },
    {
      title: 'No Telepon',
      dataIndex: 'phoneNumber',
      key: 'phoneNumber',
      width: '33%',
      render: (text) => <a>{text}</a>,
      sortDirections: ['descend', 'ascend'],
      sorter: (a, b) => a.phoneNumber?.localeCompare(b.phoneNumber),
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
      width: '33%',
      sortDirections: ['descend', 'ascend'],
      sorter: (a, b) => a.email?.localeCompare(b.email),
    },
  ];

  return (
    <>
      <Spin spinning={loading} indicator={spinIcon}>
        <div className="px-4">
          <div className="flex items-center py-5 flex-wrap">
            <Space>
              <Icon component={HomeLined} className="mr-2" />
              <RightOutlined className="mr-3 text-xs right-cursor" />
            </Space>
            <div>
              <p className="font-medium mt-2 mr-3">Daftar Donor</p>
            </div>
            <Space>
              <RightOutlined className="mr-3 text-xs right-cursor" />
            </Space>
            <div>
              <Button
                style={{
                  background: '#EEFFF4',
                  color: '#01A24A',
                  borderRadius: '8px',
                  border: 'none',
                }}
                className="flex justify-center items-center mt-2"
              >
                <span className="font-medium text-center">Detail Donor</span>
              </Button>
            </div>
          </div>
          <div
            className="overflow-hidden mb-4 mt-2"
            style={{ borderRadius: '8px', border: '1px solid #EAECF0' }}
          >
            <div
              className=""
              style={{ background: '#00BD52', height: '10px' }}
            ></div>
            <div className="px-5 py-5">
              <p className="text-heading">Detail Donor</p>
            </div>
            <hr />
            <div className="flex place-items-center px-5 mt-4 mb-4">
              <div>
                <div className="mb-4">
                  <p className="text-sub-heading">Nama Donor</p>
                  <span className="text-body">{dataDonor?.name || '-'}</span>
                </div>
                <div className="mb-4">
                  <p className="text-sub-heading">Program Tematik</p>
                  {dataDonor?.sectors ? (
                    dataDonor?.sectors.map((item) => (
                      <span className="text-body">
                        {item.name}
                        <br />
                      </span>
                    ))
                  ) : (
                    <span className="text-body">{'-'}</span>
                  )}
                </div>
                <div className="mb-4">
                  <p className="text-sub-heading">Email</p>
                  {dataDonor?.emails ? (
                    dataDonor?.emails.map((item) => (
                      <span className="text-body">
                        {item}
                        <br />
                      </span>
                    ))
                  ) : (
                    <span className="text-body">{'-'}</span>
                  )}
                </div>
                <div className="mb-4">
                  <p className="text-sub-heading">No Telepon</p>
                  {dataDonor?.phoneNumbers ? (
                    dataDonor?.phoneNumbers.map((item) => (
                      <span className="text-body">
                        {item}
                        <br />
                      </span>
                    ))
                  ) : (
                    <span className="text-body">{'-'}</span>
                  )}
                </div>
                <div>
                  <p className="text-sub-heading">Website</p>
                  <span className="text-body">{dataDonor?.website || '-'}</span>
                </div>
              </div>
            </div>
            <hr className="mb-6" />
          </div>

          <div
            className="overflow-hidden mt-6"
            style={{ borderRadius: '8px', border: '1px solid #EAECF0' }}
          >
            <div
              className=""
              style={{ background: '#00BD52', height: '10px' }}
            ></div>
            <div className="px-5 py-5">
              <p className="text-heading mb-2">Accredited Entity</p>
              <span className="text-slate-600">Informasi Organisasi</span>
            </div>
            <hr />
            <div className="flex place-items-center px-5 mt-4 mb-4">
              <div style={{ width: '100%' }}>
                <div>
                  <p className="text-sub-heading mb-2">Nama Organisasi</p>
                  <span className="text-body">
                    {dataDonor?.organizationName || '-'}
                  </span>
                </div>
                <div
                  className="mt-4 mb-4"
                  style={{
                    border: '1px solid #EAECF0',
                    borderRadius: '12px',
                    boxShadow:
                      '0px 1px 3px rgba(16, 24, 40, 0.1), 0px 1px 2px rgba(16, 24, 40, 0.06)',
                  }}
                >
                  <Spin tip="Loading" spinning={loading}>
                    <Table
                      columns={columns}
                      dataSource={acreditedEntities}
                      pagination={false}
                    />
                  </Spin>
                </div>
              </div>
            </div>
            <hr className="mb-6" />
          </div>
        </div>
      </Spin>
    </>
  );
};

export default DetailDataDonor;
