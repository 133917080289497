import React, { useMemo, useState } from 'react';
import { Button, Col, Collapse, Input, Row, Select } from 'antd';
import { PlusOutlined, DeleteOutlined } from '@ant-design/icons';
import { DeleteIcon } from 'components/atoms/SVG';
import { OutcomeForm } from './OutcomeForm';
import { MASTER_DATA_URL } from 'services/config';
import axios from 'axios';

export const PriorityProgram = ({
  Form,
  form,
  disable,
  expandIcon,
  handlePanelChange,
  requiredField,
  name,
}) => {
  const { Panel } = Collapse;
  const [listImpact, setListImpact] = useState([]);
  const [listIndicatorImpact, setListIndicatorImpact] = useState([]);
  const [listThematicProgram, setListThematicProgram] = useState([]);

  const loadListImpact = () => {
    axios
      .get(`${MASTER_DATA_URL}/Impact/list`)
      .then((res) => {
        if (res.data) {
          let arr = [];
          res.data.data?.forEach((item) => {
            arr.push({
              ...item,
              key: item.id,
              value: item.nama,
              label: item.nama,
            });
          });

          setListImpact(arr);
        }
      })
      .catch((err) => {
        // let arr = [{id: '2495a538-3d79-4d09-8a61-62eea4321883', value: 'Forest, Land-use and Sustainable Ecosystems Management', label: 'Forest, Land-use and Sustainable Ecosystems Management'}];
        let arr = [];
        setListImpact(arr);

        console.log(err);
      });
  };

  const loadListIndicatorImpact = () => {
    axios
      .get(`${MASTER_DATA_URL}/IndicatorImpact/list`)
      .then((res) => {
        if (res.data) {
          let arr = [];
          res.data.data?.forEach((item) => {
            arr.push({
              ...item,
              key: item.id,
              value: item.numberingFormat + ' ' + item.nama,
              label: item.numberingFormat + ' ' + item.nama,
            });
          });

          setListIndicatorImpact(arr);
        }
      })
      .catch((err) => {
        // let arr = [{id: '2495a538-3d79-4d09-8a61-62eea4321883', value: 'Forest, Land-use and Sustainable Ecosystems Management', label: 'Forest, Land-use and Sustainable Ecosystems Management'}];
        let arr = [];
        setListImpact(arr);

        console.log(err);
      });
  };

  const loadListThematicProgram = () => {
    axios
      .get(`${MASTER_DATA_URL}/ThematicProgrammes/list`)
      .then((res) => {
        if (res.data) {
          let arr = [];

          res.data.data?.forEach((item) => {
            arr.push({
              ...item,
              key: item.id,
              value: item.nama,
              label: item.nama,
            });
          });

          setListThematicProgram(arr);
        }
      })
      .catch((err) => {
        let arr = [
          {
            id: '2495a538-3d79-4d09-8a61-62eea4321883',
            value: 'Forest, Land-use and Sustainable Ecosystems Management',
            label: 'Forest, Land-use and Sustainable Ecosystems Management',
          },
        ];
        setListThematicProgram(arr);
        console.log(err);
      });
  };

  useMemo(() => {
    loadListImpact();
    loadListIndicatorImpact();
    loadListThematicProgram();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form]);

  const deletePanel = (remove, field) => (
    <Button
      size="large"
      style={{
        borderRadius: '8px',
        width: '50px',
        height: '48px',
        background: '#F04438',
        color: '#FFFF',
        border: 'none',
      }}
      className="flex px-2 py-2 pl-4 pr-4 justify-center items-center"
      icon={<DeleteIcon stroke={'#FFF'} />}
      onClick={() => {
        remove(field.name);
      }}
    ></Button>
  );

  return (
    <div className="">
      <Collapse
        expandIcon={expandIcon}
        defaultActiveKey={['1']}
        expandIconPosition="end"
        onChange={handlePanelChange}
      >
        <Form.Item shouldUpdate>
          {() => (
            <>
              <Form.List name={name}>
                {(priorityPrograms, { add, remove }) => (
                  <>
                    <div>
                      {priorityPrograms.map(
                        (priorityProgram, priorityProgramIndex) => {
                          return (
                            <div key={priorityProgram.key}>
                              <Collapse
                                expandIcon={expandIcon}
                                defaultActiveKey={['1']}
                                expandIconPosition="end"
                                onChange={handlePanelChange}
                                className="mt-4"
                              >
                                <Panel
                                  className="custom-panel px-5 pb-4"
                                  style={{ color: 'black !important' }}
                                  header={`Program Prioritas #${
                                    priorityProgramIndex + 1
                                  }`}
                                  key="1"
                                  extra={
                                    priorityProgramIndex > 0 &&
                                    !disable &&
                                    deletePanel(remove, priorityProgram)
                                  }
                                >
                                  {name === 'priorityPrograms' && (
                                    <div className="mt-4">
                                      <label className="field-required font-medium">
                                        Program Tematik
                                      </label>
                                      <Form.Item
                                        name={[
                                          priorityProgramIndex,
                                          'thematicProgrammes',
                                        ]}
                                        rules={requiredField}
                                      >
                                        <Select
                                          placeholder="Program Tematik"
                                          type="text"
                                          className="mt-2"
                                          disabled={disable}
                                          options={listThematicProgram}
                                          onChange={(value, option) => {
                                            form.setFieldValue(
                                              [
                                                name,
                                                priorityProgramIndex,
                                                'originalId',
                                              ],
                                              option.id
                                            );
                                          }}
                                        />
                                      </Form.Item>
                                    </div>
                                  )}

                                  <div className="mt-4">
                                    <label className="field-required font-medium">
                                      Tujuan Program
                                    </label>
                                    <Form.Item
                                      name={[
                                        priorityProgramIndex,
                                        'programGoals',
                                      ]}
                                    >
                                      <Input
                                        placeholder="Tujuan Program"
                                        type="text"
                                        className="form-input mt-2"
                                        disabled={disable}
                                      />
                                    </Form.Item>
                                  </div>

                                  <hr
                                    style={{ width: '100%' }}
                                    className="break-line mb-4"
                                  />

                                  <div className="">
                                    {name === 'priorityPrograms' && (
                                      <>
                                        <label className="font-medium">
                                          Impact
                                        </label>
                                        <Form.Item
                                          style={{ marginBottom: 10 }}
                                          name={[
                                            priorityProgramIndex,
                                            'impact',
                                            'impactName',
                                          ]}
                                        >
                                          <Select
                                            placeholder="Impact"
                                            type="text"
                                            className="mt-2"
                                            disabled={disable}
                                            options={listImpact}
                                            onChange={(value, option) => {
                                              let impact = {
                                                originalId: option.id,
                                                impactName: value,
                                                indicators: [{}],
                                              };
                                              form.setFieldValue(
                                                [
                                                  name,
                                                  priorityProgramIndex,
                                                  'impact',
                                                ],
                                                impact
                                              );
                                            }}
                                          />
                                        </Form.Item>

                                        <label className="font-medium">
                                          Indicator
                                        </label>
                                        <Form.Item
                                          style={{ margin: 0 }}
                                          shouldUpdate
                                        >
                                          <Form.List
                                            name={[
                                              priorityProgramIndex,
                                              'impact',
                                              'indicators',
                                            ]}
                                          >
                                            {(indicators, { add, remove }) => (
                                              <>
                                                {indicators.map(
                                                  (indicator, index) => {
                                                    return (
                                                      <Row gutter={12}>
                                                        <Col
                                                          span={
                                                            disable ? 24 : 22
                                                          }
                                                        >
                                                          <Form.Item
                                                            style={{
                                                              margin: 0,
                                                            }}
                                                            name={[
                                                              index,
                                                              'indicatorName',
                                                            ]}
                                                          >
                                                            <Select
                                                              placeholder="Indicator"
                                                              type="text"
                                                              className="mt-2"
                                                              disabled={disable}
                                                              options={
                                                                listIndicatorImpact
                                                              }
                                                              onChange={(
                                                                value,
                                                                option
                                                              ) => {
                                                                let indicator =
                                                                  {
                                                                    originalId:
                                                                      option.id,
                                                                    indicatorName:
                                                                      value,
                                                                  };
                                                                form.setFieldValue(
                                                                  [
                                                                    name,
                                                                    priorityProgramIndex,
                                                                    'impact',
                                                                    'indicators',
                                                                    index,
                                                                  ],
                                                                  indicator
                                                                );
                                                              }}
                                                            />
                                                          </Form.Item>
                                                        </Col>
                                                        {index > 0 &&
                                                          !disable && (
                                                            <Col span={1}>
                                                              <Button
                                                                style={{
                                                                  width: '100%',
                                                                  height: '48',
                                                                }}
                                                                className="btn-default mt-2"
                                                                onClick={() =>
                                                                  remove(index)
                                                                }
                                                              >
                                                                <DeleteOutlined />
                                                              </Button>
                                                            </Col>
                                                          )}
                                                        {index >
                                                          indicators.length -
                                                            2 &&
                                                          !disable && (
                                                            <Col span={1}>
                                                              <Button
                                                                style={{
                                                                  width: '100%',
                                                                  height:
                                                                    '48px',
                                                                }}
                                                                className="btn-default mt-2"
                                                                onClick={() =>
                                                                  add()
                                                                }
                                                              >
                                                                <PlusOutlined />
                                                              </Button>
                                                            </Col>
                                                          )}
                                                      </Row>
                                                    );
                                                  }
                                                )}
                                              </>
                                            )}
                                          </Form.List>
                                        </Form.Item>
                                      </>
                                    )}

                                    <div>
                                      <OutcomeForm
                                        Form={Form}
                                        form={form}
                                        disable={disable}
                                        name={name}
                                        priorityProgramIndex={
                                          priorityProgramIndex
                                        }
                                      />
                                    </div>
                                  </div>
                                </Panel>
                              </Collapse>
                            </div>
                          );
                        }
                      )}
                    </div>
                    <hr className="mb-6 mt-4" />
                    {!disable && (
                      <div className="flex items-center justify-center">
                        <Button
                          style={{ width: '98%', height: '52px' }}
                          className="btn-default mt-4"
                          disabled={disable}
                          icon={<PlusOutlined />}
                          onClick={() => add({ impact: { indicators: [{}] } })}
                        >
                          Tambah Program Prioritas
                        </Button>
                      </div>
                    )}
                  </>
                )}
              </Form.List>
            </>
          )}
        </Form.Item>
      </Collapse>
    </div>
  );
};
