/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useMemo } from 'react';
import {
  Space,
  Table,
  Button,
  Input,
  Modal,
  Spin,
  Pagination,
  Select,
  Tag,
  DatePicker
} from 'antd';
import { NavLink } from 'react-router-dom';
import Icon, {
  PlusOutlined,
  SearchOutlined,
  EyeOutlined,
  RightOutlined,
  LoadingOutlined,
} from '@ant-design/icons';
import axios from 'axios';
import { useNavigate } from "react-router-dom";
import moment from 'moment';
import "../index.scss";
import { BASE_URL, OIDC } from 'services/config';
import store from 'app/store';

const HomeLined = () => (
  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M5.66667 13.1667H12.3333M8.18141 1.30333L2.52949 5.69927C2.15168 5.99312 1.96278 6.14005 1.82669 6.32405C1.70614 6.48704 1.61633 6.67065 1.56169 6.86588C1.5 7.08627 1.5 7.32558 1.5 7.80421V13.8333C1.5 14.7667 1.5 15.2335 1.68166 15.59C1.84144 15.9036 2.09641 16.1585 2.41002 16.3183C2.76654 16.5 3.23325 16.5 4.16667 16.5H13.8333C14.7668 16.5 15.2335 16.5 15.59 16.3183C15.9036 16.1585 16.1586 15.9036 16.3183 15.59C16.5 15.2335 16.5 14.7667 16.5 13.8333V7.80421C16.5 7.32558 16.5 7.08627 16.4383 6.86588C16.3837 6.67065 16.2939 6.48704 16.1733 6.32405C16.0372 6.14005 15.8483 5.99312 15.4705 5.69927L9.81859 1.30333C9.52582 1.07562 9.37943 0.961766 9.21779 0.918001C9.07516 0.879384 8.92484 0.879384 8.78221 0.918001C8.62057 0.961766 8.47418 1.07562 8.18141 1.30333Z" stroke="#667085" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round" />
  </svg>
);

const CalenderIcon = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M14.166 2.50016H17.4993C17.7204 2.50016 17.9323 2.58796 18.0886 2.74424C18.2449 2.90052 18.3327 3.11248 18.3327 3.3335V16.6668C18.3327 16.8878 18.2449 17.0998 18.0886 17.2561C17.9323 17.4124 17.7204 17.5002 17.4993 17.5002H2.49935C2.27834 17.5002 2.06637 17.4124 1.91009 17.2561C1.75381 17.0998 1.66602 16.8878 1.66602 16.6668V3.3335C1.66602 3.11248 1.75381 2.90052 1.91009 2.74424C2.06637 2.58796 2.27834 2.50016 2.49935 2.50016H5.83268V0.833496H7.49935V2.50016H12.4993V0.833496H14.166V2.50016ZM12.4993 4.16683H7.49935V5.8335H5.83268V4.16683H3.33268V7.50016H16.666V4.16683H14.166V5.8335H12.4993V4.16683ZM16.666 9.16683H3.33268V15.8335H16.666V9.16683Z" fill="#667085" />
  </svg>
);
const DeleteIcon = () => (
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M19.3333 11.0003V10.3337C19.3333 9.40024 19.3333 8.93353 19.1517 8.57701C18.9919 8.2634 18.7369 8.00844 18.4233 7.84865C18.0668 7.66699 17.6001 7.66699 16.6667 7.66699H15.3333C14.3999 7.66699 13.9332 7.66699 13.5767 7.84865C13.2631 8.00844 13.0081 8.2634 12.8483 8.57701C12.6667 8.93353 12.6667 9.40024 12.6667 10.3337V11.0003M14.3333 15.5837V19.7503M17.6667 15.5837V19.7503M8.5 11.0003H23.5M21.8333 11.0003V20.3337C21.8333 21.7338 21.8333 22.4339 21.5608 22.9686C21.3212 23.439 20.9387 23.8215 20.4683 24.0612C19.9335 24.3337 19.2335 24.3337 17.8333 24.3337H14.1667C12.7665 24.3337 12.0665 24.3337 11.5317 24.0612C11.0613 23.8215 10.6788 23.439 10.4392 22.9686C10.1667 22.4339 10.1667 21.7338 10.1667 20.3337V11.0003" stroke="#667085" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round" />
  </svg>
);

const GreyDotIcon = () => (
  <svg width="6" height="6" viewBox="0 0 6 6" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="3" cy="3" r="3" fill="#667085" />
  </svg>
);
const OrangeDotIcon = () => (
  <svg width="6" height="6" viewBox="0 0 6 6" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="3" cy="3" r="3" fill="#F79009" />
  </svg>
);

const GreenDotIcon = () => (
  <svg width="6" height="6" viewBox="0 0 6 6" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="3" cy="3" r="3" fill="#27AE5F" />
  </svg>
);

const RedDotIcon = () => (
  <svg width="6" height="6" viewBox="0 0 6 6" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="3" cy="3" r="3" fill="#F04438" />
  </svg>
);

const LeftArrow = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" style={{ marginRight: "5px", marginBottom: "5px" }}>
    <path d="M15.8337 9.99984H4.16699M4.16699 9.99984L10.0003 15.8332M4.16699 9.99984L10.0003 4.1665" stroke="#344054" stroke-width="1.67" stroke-linecap="round" stroke-linejoin="round" />
  </svg>
);
const RightArrow = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" style={{ marginLeft: "5px", marginBottom: "5px" }}>
    <path d="M4.16699 9.99984H15.8337M15.8337 9.99984L10.0003 4.1665M15.8337 9.99984L10.0003 15.8332" stroke="#344054" stroke-width="1.67" stroke-linecap="round" stroke-linejoin="round" />
  </svg>
);

const ProposalSubmissionList = () => {
  let navigate = useNavigate();
  const spinIcon = <LoadingOutlined style={{ fontSize: 60 }} spin />;
  const draftStep = 1;

  const [dataProposal, setDataProposal] = useState([]);
  const [loading, setLoading] = useState();
  const [opt, setOpt] = useState(10);
  const [page] = useState(1);
  const [paginator, setPaginator] = useState();
  const [current, setCurrent] = useState(1);
  const [limit, setLimit] = useState(10);

  const [statusList] = useState([
    { value: 'approved', label: 'Approved' },
  ])

  const [listBypass] = useState([
    { value: 'staff-ppd', label: 'staff-ppd' },
    { value: 'direktur-ppd', label: 'direktur-ppd' },
    { value: 'staff-kl', label: 'staff-kl' },
    { value: 'direktur-kl', label: 'direktur-kl' },
    { value: 'direktorat-lain', label: 'direktorat-lain' },
    { value: 'direktur-utama', label: 'direktur-utama' },
  ]);

  const [selectedRBP, setSelectedRBP] = useState();

  const oidc_user = store.getState().oidc.oidc_user;
  const user = JSON.parse(oidc_user).profile;

  // HANDLE ROLE
  const handleRole = (roleByPass) => {
    let _role = user?.Jabatan + '-' + user?.Directorat;

    if (roleByPass) {
      _role = roleByPass;
    } else {
      if (user.Name === 'Superadmin Internal') {
        const queryParams = new URLSearchParams(window.location.search);
        _role = queryParams.get('role');
      } else {
        if (user?.JabatanCode === '101') {
          _role = 'direktur-utama';
        } else if (user?.DirectoratCode === '004') {
          if (user?.JabatanCode === '401') {
            _role = 'direktur-ppd';
          } else {
            _role = 'staff-ppd';
          }
        } else if (user?.DirectoratCode && user?.DirectoratCode !== '-') {
          _role = 'direktorat-lain';
        } else {
          // if(user?.JabatanCode.includes("01")){
          //   _role = "direktur-kl";
          // } else {
          //   _role = "staff-kl";
          // }
          if (user?.TypeUser === 'KL') {
            _role = 'direktur-kl';
          } else {
            _role = 'staff-kl';
          }
        }
      }
    }

    switch (_role) {
      case 'staff-ppd':
        return { title: 'staff', group: 'ppd' };
      case 'direktur-ppd':
        return { title: 'director', group: 'ppd' };
      case 'staff-kl':
        return { title: 'staff', group: 'kl' };
      case 'direktur-kl':
        return { title: 'director', group: 'kl' };
      case 'direktorat-lain':
        return { title: 'director', group: 'otherDirectorate' };
      case 'direktur-utama':
        return { title: 'director', group: 'presidentDirector' };
      default:
        return { title: 'director', group: 'presidentDirector' };
    }
  };

  const [role, setRole] = useState(handleRole());
  const [searchKey, setSearchKey] = useState("");
  const [searchStatus, setSearchStatus] = useState("");
  const [searchStartDate, setSearchStartDate] = useState("");
  const [searchEndDate, setSearchEndDate] = useState("");
  const [sortDir, setSortDir] = useState("descend");
  const [sortBy, setSortBy] = useState("createdAt");

  const loadDataProposal = () => {
    setLoading(true);

    let path = `${BASE_URL}/proposal-non-submission?page=${current}&limit=${limit}&role=${role.title}&group=${role.group}`

    if (searchKey) {
      path = path + `&search=${searchKey}`
    }

    if (searchStatus) {
      path = path + `&status=${searchStatus}`
    }

    if (searchStartDate) {
      path = path + `&startDate=${searchStartDate}`
    }

    if (searchEndDate) {
      path = path + `&endDate=${searchEndDate}`
    }

    if (sortDir) {
      path = path + `&sort=${sortDir.replace("end", "")}`
    }

    if (sortBy) {
      path = path + `&sortBy=${sortBy}`
    }

    axios.get(path)
      .then(res => {
        setDataProposal(res.data.result);
        setLoading(false);
        setPaginator(res.data.paginator);
      })
      .catch(err => {
        setLoading(false);
      })
  };

  useMemo(() => {
    loadDataProposal()
  }, [searchKey, searchStatus, searchStartDate, searchEndDate, sortDir, sortBy, current, limit])

  const onSearch = (e) => {
    if (e.key === 'Enter') {
      setCurrent(1);
      setSearchKey(e.target.value);
    }
  };

  const onSearchStatus = (status) => {
    setCurrent(1);
    setSearchStatus(status);
  };

  const onSearchStartDate = (date) => {
    setCurrent(1);
    setSearchStartDate(date);
  };

  const onSearchEndDate = (date) => {
    setCurrent(1);
    setSearchEndDate(date);
  };

  function onPaginationChange(limit) {
    setOpt(limit);
    setLimit(limit);
    setCurrent(1);
  }

  function onNumberPaginationChange(changePage) {
    setCurrent(changePage);
  }

  function onSortChange(_sortDir, _sortBy) {
    setCurrent(1);
    setSortBy(_sortBy);
    setSortDir(_sortDir);
  }

  const onDeleteProposal = (proposalId) => {
    Modal.confirm({
      title: '',
      okText: "Confirm",
      content: (
        <>
          <h1
            className=""
            style={{ fontSize: "17px", fontWeight: "600", lineHeight: "24px", textTransform: "capitalize" }}
          >
            Apakah Anda yakin akan menghapus data ini?
          </h1>
          <br />
        </>
      ),
      onOk() {
        setLoading(true);
        axios.delete(`${BASE_URL}/proposal/${proposalId}`)
          .catch(() => setLoading(false))
          .then(() => {
            setLoading(false);
            onDone();
          });
      }
    });
  }

  const onDone = () => {
    Modal.success({
      title: '',
      okText: "Confirm",
      content: (
        <>
          <h1
            className=""
            style={{ fontSize: "17px", fontWeight: "600", lineHeight: "24px", textTransform: "capitalize" }}
          >
            Data Berhasil Dihapus!
          </h1>
          <br />
        </>
      ),
      onOk() {
        loadDataProposal();
        return navigate("/proposal-non-submission");
      }
    })
  }

  function itemRender(current, type, originalElement) {
    if (type === 'prev') {
      return <span className="">
        <Icon component={LeftArrow} />
        Previous
      </span>;
    }
    if (type === 'next') {
      return <span className="">
        Next
        <Icon component={RightArrow} />
      </span>;
    }
    return originalElement;
  }

  function typeList(value) {
    let tags = [];

    switch (value.status) {
      case 'review':
        value.review.groups.forEach(group => {
          tags.push(
            (group === "Selesai Direview") ?
              { colour: "#ECFDF3", text: `${group}`, textColor: "#1C7D44", dotColor: GreenDotIcon }
              :
              { colour: "#FFFAEB", text: `${group}`, textColor: "#B54708", dotColor: OrangeDotIcon }
          );
        });
        break;
      case 'draft':
        tags.push(
          tags = { colour: "#F2F4F7", text: "Draft", textColor: "#344054", dotColor: GreyDotIcon }
        );
        break;
      case 'rejected':
        tags.push(
          tags = { colour: "#FEF3F2", text: "Ditolak", textColor: "#B42318", dotColor: RedDotIcon }
        );
        break;
      case 'approved':
        tags.push(
          tags = { colour: "#ECFDF3", text: "Disetujui", textColor: "#1C7D44", dotColor: GreenDotIcon }
        );
        break;
      default:
    }

    if (value.review) {
      return (
        tags.map((value) => {
          return <div className="flex mb-2">
            <Tag
              color={value.colour}
              style={{ color: value.textColor }}
              className="flex pl-2 pr-2 items-center"
              icon={<Icon component={value.dotColor} className="" />}
            >
              {
                value.text
              }
            </Tag>
          </div>
        })
      );
    } else {
      return <div className="flex mb-2">
        <Tag
          color={tags.colour}
          style={{ color: tags.textColor }}
          className="flex pl-2 pr-2 items-center"
          icon={<Icon component={tags.dotColor} className="" />}
        >
          {
            tags.text
          }
        </Tag>
      </div>
    }
  }

  function onClickDraft(proposal) {
    let draftStep = (proposal.draftStep === 4) ? 4 : (proposal.draftStep || 0) + 1
    return navigate(`/proposal-non-submission/create-proposal/step/${(draftStep)}/${proposal.id}`);
  }

  function onClickDetail(proposal, action) {
    if (role?.title === "director" && role?.group === "presidentDirector") {
      return navigate(`/proposal-non-submission/${action}/dirut/${proposal}?role=${role.title}&group=${role.group}`);
    } else {
      return navigate(`/proposal-non-submission/view-proposal/step/1/${proposal}?role=${role.title}&group=${role.group}`);
    }
  }

  function proposalStatus(value) {
    if (value.status === "draft") {
      return <div className="status">
        <Button style={{ border: "none", marginBottom: "3px" }} onClick={() => onDeleteProposal(value.id)}>{<DeleteIcon style={{ fontSize: "18px" }} />}</Button>
        <Button className="font-medium" type="ghost" onClick={() => onClickDraft(value)} style={{ color: "#01A24A" }}>Lanjutkan Draft</Button>
      </div>
    } else {
      if (value.review?.groups[0] === "Belum Direview" && role?.title === "director" && role?.group === "presidentDirector") {
        return <div className="status">
          <Button className="font-medium" type="ghost" onClick={() => onClickDetail(value.id, "review-proposal")} style={{ background: "#01A24A", color: "#FFF" }}>Review</Button>
        </div>
      } else {
        return <div className="status">
          <Button type="ghost" style={{ border: "none", marginBottom: "3px" }} onClick={() => onClickDetail(value.id, "view-proposal")}>{<EyeOutlined style={{ fontSize: "18px" }} />}</Button>
        </div>
      }
    }
  }

  const columns = [
    {
      title: 'No',
      key: 'number',
      width: '68px',
      height: '72px',
      render: (val, text, data) => {
        return <p>{data + 1 + ((current - 1) * limit)}</p>
      }
    },
    // {
    //   title: 'ID Proposal',
    //   key: 'number',
    //   width: '68px',
    //   height: '72px',
    //   sorter: (a, b, sortDir) => {onSortChange(sortDir, null)},
    //   render: (val, text, data) => {
    //     return <p>{val.proposalNumber}.{val.subProposalNumber}</p>
    //   }
    // },
    {
      title: 'Judul Proposal',
      width: '400px',
      sortDirections: ['descend', 'ascend'],
      sorter: (a, b, sortDir) => { onSortChange(sortDir, "generalInformation.title") },
      render: (value) => <span>{value.generalInformation?.title}</span>,
    },
    {
      title: 'Tgl Pengajuan',
      key: 'name',
      width: '400px',
      sortDirections: ['descend', 'ascend'],
      sorter: (a, b, sortDir) => { onSortChange(sortDir, "submitDate") },
      render: (value) => <span>{value.submitDate ? moment(value.submitDate).format('DD/MM/YYYY') : "-"}</span>,
    },
    {
      title: 'Nama Donor',
      key: 'sectors',
      width: '400px',
      sortDirections: ['descend', 'ascend'],
      sorter: (a, b, sortDir) => { onSortChange(sortDir, "donor.donor") },
      render: (value) => <span>{value.donor?.donor || (value.donor ? value.donor?.donor : "-")}</span>,
    },
    {
      title: 'Email',
      key: 'email',
      width: '500px',
      sortDirections: ['descend', 'ascend'],
      sorter: (a, b, sortDir) => { onSortChange(sortDir, "donor.email") },
      render: (value) => value?.donor ?
        value?.donor?.email?.map((email, emailIdx) => (
          <>
            <span>{email} <br /></span>
          </>
        ))
        :
        <span>-</span>
    },
    {
      title: 'Status',
      key: 'status',
      width: '600px',
      sorter: (a, b, sortDir) => { onSortChange(sortDir, "status") },
      render: (value) => <>
        <span>{typeList(value)}</span>
      </>
    },
    {
      title: 'Aksi',
      key: 'action',
      width: '300px',
      align: 'center',
      render: (value) => (proposalStatus(value))
    }
  ];

  return (
    <>
      <div className="flex items-center py-4 flex-wrap">
        <Space>
          <Icon component={HomeLined} className="mr-2" />
          <RightOutlined
            className="mr-3 text-xs right-cursor"
          />
        </Space>
        <div>
          <p className="font-medium" style={{ color: "#01A24A" }}>Daftar Proposal Non Submission</p>
        </div>
      </div>
      <div className="flex items-center justify-between mb-4">
        <h1 className="text-2xl font-medium">Daftar Proposal Non Submission</h1>
        {user?.Name === 'Superadmin Internal' && (
          <Select
            size="small"
            placeholder="Role Bypass (Superadmin)"
            allowClear
            type="text"
            className="mt-2"
            style={{ height: '48px', width: '300px' }}
            options={listBypass}
            onKeyDown={(e) => {
              setRole(handleRole(e));
              setSelectedRBP(e);
            }}
            onChange={(e) => {
              setRole(handleRole(e));
              setSelectedRBP(e);
            }}
          />
        )}
        {
          `${role?.title}-${role?.group}` === 'staff-ppd' ?
            <Space>
              <NavLink to={`/proposal-non-submission/create-proposal/step/${draftStep}`}>
                <Button
                  htmlType="submit"
                  icon={<PlusOutlined />}
                  className="btn-primary mt-4"
                >
                  <span className="ml-2 text-center">Ajukan Proposal</span>
                </Button>
              </NavLink>
            </Space>
            :
            <></>
        }
      </div>
      <div className="table-list" style={{ border: "1px solid #EAECF0", borderRadius: "12px" }}>
        <div className="flex gap-4 py-6 px-6">
          <Input
            size="large"
            placeholder="Cari"
            className="mt-2 pl-2"
            prefix={<SearchOutlined />}
            onKeyDown={(e) => onSearch(e)}
            style={{ height: "48px" }}
          />
          <Select
            size="small"
            placeholder="Status"
            allowClear
            type="text"
            className="mt-2"
            style={{ height: "48px", width: '95%' }}
            options={(role?.title === "staff" && role.group === "ppd") ? [{ value: 'draft', label: 'Draft' }, ...statusList] : statusList}
            onKeyDown={(e) => onSearchStatus(e)}
            onChange={(e) => onSearchStatus(e)}
          />
          <DatePicker
            suffixIcon={<CalenderIcon />}
            placeholder="Start Date"
            className="form-input mt-2"
            prefix={<CalenderIcon />}
            onChange={onSearchStartDate}
            style={{ width: '48%' }}
          >
            <Icon component={CalenderIcon} className="mr-2" style={{ fontSize: 15 }} />
          </DatePicker>
          <DatePicker
            suffixIcon={<CalenderIcon />}
            placeholder="End Date"
            className="form-input mt-2"
            prefix={<CalenderIcon />}
            onChange={onSearchEndDate}
            style={{ width: '48%' }}
          >
            <Icon component={CalenderIcon} className="mr-2" style={{ fontSize: 15 }} />
          </DatePicker>
        </div>
        <div>
          <Spin spinning={loading} indicator={spinIcon}>
            <Table
              columns={columns}
              dataSource={dataProposal}
              pagination={false}
              onChange={onSearch}
              className="px-5"
            />
            <div className="flex table-paginator-wrapper my-3 px-2 justify-between">
              <div className="flex flex-row justify-content-between mb-2 px-2 justify-between items-center">
                <div className="show align-self-center pr-2 font-medium text-sm">
                  {'Tampilkan Data'}
                </div>
                <div className="dropdown-paging align-self-center pr-2">
                  <Space direction="vertical" style={{ width: '100%' }}>
                    <Select
                      value={opt}
                      className="select-paging-ppd"
                      showArrow={true}
                      style={{ width: "60px" }}
                      options={[
                        { value: 5, label: 5 },
                        { value: 10, label: 10 },
                        { value: 30, label: 30 },
                        { value: 50, label: 50 },
                        { value: 100, label: 100 },
                      ]}
                      onChange={onPaginationChange}
                    />
                  </Space>
                </div>
                <div className="border-paging align-self-center pr-2"></div>
                <div className="total align-self-center pr-2">
                  {
                    `${dataProposal.slice(0, 1).length} - ${dataProposal.length} data dari ${paginator?.itemCount} data`
                  }
                </div>
              </div>
              <div>
                <Pagination
                  pageSize={opt || 10}
                  current={current || page}
                  total={paginator?.itemCount}
                  onChange={onNumberPaginationChange}
                  itemRender={itemRender}
                />
              </div>
            </div>
          </Spin>
        </div>
      </div>
    </>
  );
}

export default ProposalSubmissionList;